import React from 'react';
import { Link } from 'react-router-dom'; // For navigation links (optional)
import { HashLink } from 'react-router-hash-link';
import styled from 'styled-components';

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background-color: transparent;
`;

const Logo = styled.img`
  height: 50px; /* Adjust logo height */
`;

const NavLinks = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
`;

const NavLink = styled.li`
  margin-right: 1rem;

`;

const Header = () => {
  return (
    <HeaderContainer>
      <Link to="/">
        <Logo src="logo.png" alt="leadmeup.ma" />
      </Link>
      <NavLinks>
        <NavLink>
          <Link to="https://facebook.com/leadmeup/" target="_blank" rel="noopener noreferrer">
            <img src="https://akademy.ma/images/facebook.png" alt="facebook" title="facebook"/>
          </Link>
        </NavLink>
        <NavLink>
          <Link to="https://www.linkedin.com/company/leadme-up/about/" target="_blank" rel="noopener noreferrer">
            <img src="https://akademy.ma/images/linkedin.png" alt="linkedin" title="linkedin" />
            </Link>
        </NavLink>
        <NavLink>
          <HashLink to="#contact">Contact</HashLink>
        </NavLink>
      </NavLinks>
    </HeaderContainer>
  );
};

export default Header;
