// Hero.js (example)
import React, { useState, useEffect } from 'react';
import { animated, useSpring } from 'react-spring'; // Animation library
import styled from 'styled-components';

const Hero = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
//    const trigger = useScrollTrigger({ onEnter: () => setIsScrolled(true), onLeave: () => setIsScrolled(false) });
//    return () => trigger.cleanup();
  }, []);

  const heroAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: isScrolled ? 1 : 0 },
    config: { duration: 1000 },
  });

  return (
    <HeroContainer style={heroAnimation} id="about">
      <h1>Lead Me UP.ma is comming soon</h1>
      <h4>Come back and visit us again in a few days. Thank you for your patience!</h4>
    </HeroContainer>
  );
};

const HeroContainer = styled.div`
  background-image: url('landing.png');
  background-size: cover;
  background-position: center;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
`;

export default Hero;

